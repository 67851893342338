import React from "react"
import { Bio } from "../../components"

export default function Footer() {
  return (
    <footer>
      <Bio />
    </footer>
  )
}
